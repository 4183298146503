import Footer from "../Footer";
import FilteredPackages from "../FilteredPackages";

const Packages = () => {

  return (
    <>
      <FilteredPackages />
      <Footer />
    </>
  );
};

export default Packages;
